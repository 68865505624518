import { Formik, Form, ErrorMessage, Field } from "formik"
import TooltipLabel from "../info/tooltipLabel";
import {toast, ToastContainer} from "react-toastify";
import * as Yup from 'yup'
import 'react-toastify/dist/ReactToastify.css';



const CardTransactionIdSearchForm = (props) => {

    return (
        <div>
            <Formik
                initialValues={{
                    cardTransactionId: '',
                }}

                validationSchema={Yup.object({
                    cardTransactionId: Yup.string()
                        .min(5,'Invalid card transaction statement').required('Required')
                })}

                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                        props.handlePayloadChange({
                            cardStatementTransactionId:  values.cardTransactionId.toLowerCase()
                        })
                }}
            >
                {formik => (
                    <div className="bg-body flex justify-center flex-wrap -mx-3 mb-2">
                        <Form className="mx-wid-900 bg-white shadow rounded px-8 pt-6 pb-8 mb-4 w-full sm:w-2/3 mt-4 ml-6 mr-6">
                            <div className="mb-4">
                               
                                <TooltipLabel htmlFor="cardTransactionId" labelText="Card Transaction ID" tooltipText="Search by card transaction id. You can find this in your bank statement on your successful transaction, it should look like this: MARVEL*xdpo976" />
                                
                                <Field data-tip data-for="cardTransactionId" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="cardTransactionId"
                                    name="cardTransactionId"
                                    type="cardTransactionId"
                                />
                              
                                <ErrorMessage component="div" className="text-red-600" name="cardTransactionId" />
                            </div>
                            <div className="flex items-center justify-center">
                                <button className="w-1/4 bg-component hover:bg-component text-white font-bold py-2 px-4 rounded" type="submit">Search</button>
                            </div>

                        </Form>
                        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} closeOnClick={false} hideProgressBar />
                    </div>

                )}
            </Formik>
        </div>
    )
}

export default CardTransactionIdSearchForm;