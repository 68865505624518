import React, {useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import RefundItemsSelectorModel from "./refundItemsSelectorModel";
import moment from "moment-timezone";


const RefundableReceipt = (props) => {
    const [products, setProducts] = useState([])
    const [orderSubTotal, setOrderSubTotal] = useState(0)

    const [error, setError] = useState("")
    const [data, setData] = useState("");
    const [itemSelect, setItemSelect] = useState("")
    const [currency, setCurrency] = useState("")
    const [showRefundItemQuantitySelectorModel, setShowRefundItemQuantitySelectorModel] = useState(false)
    const [items, setItems] = useState([])
    const [refundTotal, setRefundTotal] = useState(0)
    const [loaded, setLoaded] = useState("")
    const [canSubmit, setCanSubmit ] = useState(false)
    const [previousRefund, setPreviousRefund ] = useState([])
    const refundPortalApiBaseURL = process.env.REACT_APP_REFUND_APP_API_URL

    const authKeyState = useState(localStorage.getItem('auth_key')) || null;
    let authKey = '';
    if (authKeyState && authKeyState[0]) {
        authKey = authKeyState[0]
    }

    useEffect(() => {
        setProducts(props.order.products)
    },[props.order.products]);

    const calculateItemTotalPrice = () => {
        if (!products) {
            return "";
        }

        let productTotalAmount = 0
        let refundTotalCalculatedAmount = 0
        for (const product of products) {
            productTotalAmount += (product.basePrice * (product.quantity - product.refundQuantity))
            if(product.refundQuantity){
                refundTotalCalculatedAmount +=(product.basePrice * product.refundQuantity)
            }
        }
        setOrderSubTotal(productTotalAmount)
        if (productTotalAmount !== props.order.productTotal){
            setRefundTotal(props.order.productTotal - (productTotalAmount + refundTotalCalculatedAmount))
        }
    }

    const  replaceItemValues = (productId, updatedQuantity) => {
        let item = products.find(element => element.productId === productId)

        const ItemQuantityAdjust = item.quantity-updatedQuantity

        if(ItemQuantityAdjust<0){
            toast.warning("Incorrect quantity value");
            return ""
        }

        items.push(getItemWithQuantity(item,updatedQuantity))
        item.quantity = item.quantity-updatedQuantity
    }

    const handleRefundQuantity = (quantity, itemId, refundQuantity) => {
        replaceItemValues(itemId,refundQuantity)
        calculateItemTotalPrice()
        setCanSubmit(true)
    }

    function selectItem(product, currency) {
        setItemSelect(product);
        setCurrency(currency);
        setShowRefundItemQuantitySelectorModel(true);
    }

    const getItemWithQuantity = (product,quantity) => {
        return {id:product.productId, quantity:{unit: String(product.unit), value: Number(quantity)}, type: String(product.type)}
    }

    const cancelRefundProcess = () => {
        setProducts([])
        setItemSelect(0);
        setCurrency(0);
        props.setShowReceipt(false)
    }

    const submitRefund = () => {
        if(!refundTotal){
            return ""
        }
        const refundPayload = {
            orderId:Number(props.order.orderId),
            refundItems:items
        }
        // console.log(refundPayload)

        toast.promise(axios.post(refundPortalApiBaseURL + "/v1/order/refund", refundPayload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authKey
                }
            }),
            {
                pending: 'Loading...'
            }
        )
            .then((response) => {
                toast.success("successfully refunded.")
                processDataFetch(response.data)
            })
            .catch((error) => {
                toast.error("Failed to refund");
                processDataFetchError(error) })
            .finally(() => setLoaded(true));
    }
    const processDataFetch = (data) => {
        setData(data);
        cancelRefundProcess()
    }
    const processDataFetchError = (error) => {
        if(error?.response?.status === 401) {
            window.location.replace(window.location.origin)
        }

        setError(error);
    };

    const renderProductContent = () => {
        if (!props.order.shoppingTripId) {
            return "";
        }
        if (!products) {
          return "";
        }

        return products.map((product, index) => {
            {/*Products will only have a title if priced by G10X*/
            }
            if (!product.title) {
                return;
            }

            return (
                <tr className="hover:bg-sky-50" key={product.title} onClick={() => selectItem(product, props.order.currencyCode)}>
                    <td className='border-b text-center  border-color-brand p-4 pl-8'>{product.title}</td>
                    <td className='border-b text-center  border-color-brand p-4 pl-8'>{product.quantity - product.refundQuantity}</td>
                    <td className='border-b text-center  border-color-brand p-4 pl-8'>{parseFloat(product.basePrice).toFixed(2)}</td>
                    <td className='border-b text-center  border-color-brand p-4 '>{parseFloat((product.quantity - product.refundQuantity) * product.basePrice).toFixed(2)}</td>
                    <td className='border-b text-center  border-color-brand p-4 '>
                    </td>
                </tr>
            )
        })
    }

    const renderPreviousRefundsTitle = () => {
        if (!previousRefund) {
            return "";
        }

        return (
            <>
                <tr key={"promotionsTitle"}>
                    <td className='border-b text-center rounded border-color-brand p-4 pl-8 bold'><b>Previous Product refunded</b></td>
                    <td className='border-b text-center rounded border-color-brand p-4 pl-8'></td>
                    <td className='border-b text-center rounded border-color-brand p-4 pl-8'></td>
                    <td className='border-b text-center rounded border-color-brand p-4 pl-8'></td>
                </tr>
            </>
        )
    }
    const renderPreviousRefundsContent = () => {
        if(!props.order.products){
            return ;
        }
        let productList = []
        for (const product of props.order.products) {
            // let item = productList.find(value => value.productId === product.productId)
            if(product.refundQuantity ){
                productList.push(product)
            }
        }


        return productList.map((product, index) => {
            return (
                <tr  key={product.productId} >
                    <td className='border-b text-center  border-color-brand p-4 pl-8'>{product.title}</td>
                    <td className='border-b text-center  border-color-brand p-4 pl-8'>{product.refundQuantity}</td>
                    <td className='border-b text-center  border-color-brand p-4 pl-8'>{parseFloat(product.basePrice).toFixed(2)}</td>
                    <td className='border-b text-center  border-color-brand p-4 '>{parseFloat(product.refundQuantity * product.basePrice).toFixed(2)}</td>
                    <td className='border-b text-center  border-color-brand p-4 '></td>
                </tr>
            )
        })
    }

    const renderProductTotal = () => {
        if (!props.order) {
            return "";
        }
        if(!products){
            return "";
        }

        let total = 0
        for (const product of products) {
            total += (product.basePrice * (product.quantity - product.refundQuantity))
        }

        return (
            <>
                <tr key={"subTotal"}>
                    <td className=' text-center rounded border-color-brand p-4 pl-8 bold'><b>Sub Total</b></td>
                    <td className=' text-center rounded border-color-brand p-4 pl-8'></td>
                    <td className=' text-center rounded border-color-brand p-4 pl-8'></td>
                    <td className=' text-center rounded border-color-brand p-4  bold'>{parseFloat(total).toFixed(2)}</td>
                </tr>
            </>
        )
    }

    const renderFinalTotal = () => {
        if (!props.order) {
            return "";
        }

        return (
            <>
                <tr key={"orderSubTotal"}>
                    <td className=' text-center rounded border-color-brand p-4 pl-8 bold'><b>Order Total</b>
                    </td>
                    <td className=' text-center rounded border-color-brand p-4 pl-8'></td>
                    <td className=' text-center rounded border-color-brand p-4 pl-8'></td>
                    <td className=' text-center rounded border-color-brand p-4  bold'>
                        <b>{parseFloat(props.order.promotionsAppliedTotal).toFixed(2)}</b></td>
                </tr>
            </>
        )
    }

    const renderRefundTotal = () => {
        if (!refundTotal) {
            return "";
        }


        return (
            <>
                <tr key={"orderSubTotal"}>
                    <td className=' text-center rounded border-color-brand p-4 pl-8 bold'><b>Refund Total</b></td>
                    <td className=' text-center rounded border-color-brand p-4 pl-8'></td>
                    <td className=' text-center rounded border-color-brand p-4 pl-8'></td>
                    <td className=' text-center rounded border-color-brand p-4  bold'>
                        <b>{parseFloat(refundTotal).toFixed(2)}</b></td>
                </tr>
            </>
        )
    }

    if (!props.order.shoppingTripId) {
        return <></>
    }

    return (
        <>
            <div className="flex justify-center flex-wrap -mx-3 mb-2 ">
                <div className="bg-white shadow rounded">
                    <div className="mb-3 mt-3 ml-4 mr-4">

                        <div className="relative">
                            <div className='text-primary-brand-color text-2xl mt-2 flex justify-center flex-wrap'>
                                <h2 >Order Transaction</h2>
                            </div>
                            <div className='text-primary-brand-color  mt-2 flex justify-left flex-wrap'>

                                <div className="text-center center mt-5">
                                    <h3 className="mb-1">Order ID: {props.order.orderId}</h3>
                                    <h3 className="mb-1">Store: Marvel Stadium - Outlet 118</h3>
                                    <h3 className="mb-1">Date : {moment(props.order.shoppingTripEndDateTime).tz('Australia/Melbourne').format("MMMM Do YYYY, h:mm:ss a") }</h3>
                                </div>

                            </div>
                            {/* <p className="my-4 text-slate-500 text-lg leading-relaxed"> */}
                            <table className="bg-white  rounded table-auto border-collapse text-sm w-full overflow-x-hidden">
                                <thead>
                                <tr className='border-b rounded border-color-brand font-medium p-4 pl-8 pb-3 mt-3 text-left'
                                    key={"head"}>
                                    <th className='border-b text-center border-color-brand font-medium p-4 pl-8 table-head'>
                                        <b>Product</b></th>
                                    <th className='border-b text-center border-color-brand font-medium p-4 pl-8 table-head'>
                                        <b>Quantity</b></th>
                                    <th className='border-b text-center border-color-brand font-medium p-4 pl-8 table-head'>
                                        <b>Unit Price ({props.order.currencyCode})</b></th>
                                    <th className='border-b text-center border-color-brand font-medium p-4  table-head t'>
                                        <b>Total ({props.order.currencyCode})</b></th>
                                </tr>
                                </thead>
                                <tbody>
                                {renderProductContent()}
                                {renderProductTotal()}
                                {renderRefundTotal()}
                                {renderFinalTotal()}
                                {/*{renderPreviousRefundsTitle()}*/}
                                {/*{renderPreviousRefundsContent()}*/}
                                </tbody>
                            </table>

                            <div
                                className="flex items-center p-6 border-t border-solid rounded-b justify-between">
                                <button
                                    className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded"
                                    type="button" onClick={() => cancelRefundProcess()}>
                                    Cancel Refund
                                </button>
                                <button
                                    className="bg-orange-600 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                                    type="button" disabled={!canSubmit} onClick={() => submitRefund()} >
                                    Submit Refund
                                </button>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <div className='flex justify-center flex-wrap overflow-x-scroll -mx-3 mb-2'>
                {/*{itemSelect && ( <RefundItemsSelectorModel currency={currency} setRefundQuantity={handleRefundQuantity} ordersScopeToken={props.order.ordersScopeToken} item={itemSelect} showModal={showRefundItemQuantitySelectorModel} setModalShow={setShowRefundItemQuantitySelectorModel} />)}*/}
                {itemSelect && (<RefundItemsSelectorModel currency={currency} setRefundQuantity={handleRefundQuantity} ordersScopeToken={props.order.ordersScopeToken} item={itemSelect} showModal={showRefundItemQuantitySelectorModel} setModalShow={setShowRefundItemQuantitySelectorModel} />)}
            </div>
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} closeOnClick={false} hideProgressBar />
        </>
    );

}
export default RefundableReceipt;