import { React, useRef } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik"
import * as Yup from 'yup'
import TooltipLabel from "../info/tooltipLabel";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';

const SendEmailReceiptModal = (props) => {

  const ref = useRef(null);
  const receiptPortalApiBaseURL = process.env.REACT_APP_API_BASE_URL

  const sendEmailReceipt = (submittedEmail) => {
    const payload = (submittedEmail === "") ? {} : JSON.stringify({ emailAddress: submittedEmail })
    const url = receiptPortalApiBaseURL + "/orders/" + props.order.shoppingTripId + "/email"


    toast.promise(axios
      .post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + props.orderScopingToken
        }
      }),
      {
        pending: 'Sending email receipt...',
        success: 'Email receipt sent',
        error: {
          render({ data }) {
            return data.message
          }
        }
      })
      .then((response) => processSendEmailSuccess(response))
      .catch((error) => processSendEmailError(error));
  }

  const processSendEmailSuccess = (response) => {
    props.setShowModal(false);
  }

  const processSendEmailError = (error) => {

  }

  const submitForm = () => {
    const submitButton = ref.current;
    submitButton.click();
  }

  if (!props.order.shoppingTripId) {
    return <></>
  }

  return (
    <>
      {props.showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="w-full text-3xl font-semibold">
                    Send Email Receipt
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 modal-body">
                  <div className="flex justify-left ml-4 flex-wrap -mx-3">
                    <p>Please enter the email address that you would like to send the receipt to below.</p>
                  </div>
                  <div>
                    <Formik
                      initialValues={{
                        email: '',
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string().email('Invalid email address.').required("Required.")
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        sendEmailReceipt(values.email);
                      }}
                    >
                      {formik => (
                        <div className="flex justify-center flex-wrap -mx-3 mb-6">
                          <Form className="px-8 pt-6 mb-4 w-full" id="emailAddressFrom">
                            <div className="mb-4">

                              <TooltipLabel htmlFor="email" labelText="Email Address" tooltipText="Enter an email address that you want to email the receipt to." />

                              <Field data-tip data-for="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="email"
                                name="email"
                                type="email"
                              />

                              <ErrorMessage component="div" className="text-red-600" name="email" />
                            </div>
                            <div className="flex items-center justify-center">
                              <button id="submitButton" ref={ref} className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded" hidden type="submit">Search</button>
                            </div>
                          </Form>
                        </div>

                      )}
                    </Formik>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded mr-2"
                    type="button"
                    onClick={() => submitForm()}
                  >
                    Send Email
                  </button>
                  <button
                    className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          <ToastContainer position={toast.POSITION.BOTTOM_CENTER} closeOnClick={false} hideProgressBar />
        </>
      ) : null}
    </>
  );
};

export default SendEmailReceiptModal