import {React, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";


const RefundItemsSelectorModel = (props) => {
    const [itemsQuantity, setItemsQuantity] = useState([])
    const [refundQuantity, setRefundQuantity] = useState(0)
    const [itemRefundTotal, setItemRefundTotal] = useState(0)
    const [itemId, setItemId]= useState('')
    const [selectedItemsQuantity, setSelectedItemsQuantity] = useState(0)

    useEffect(() => {
        setItemId(props.item.productId)
        setItemRefundTotal(0)

        let result = [];
        for (let i = (props.item.quantity - props.item.refundQuantity); i >= 0; i--) {
            result.push(i);
        }
        result.reverse()
        setItemsQuantity(result)
    },[props.item.productId]);

    const handleSelectedItemsQuantity = (selectedItemValue) =>{
        if(selectedItemValue > props.item.quantity){
            toast.error("Incorrect refund quantity");
            return ""
        }

        const ItemQuantityAdjust = props.item.quantity - selectedItemValue

        setRefundQuantity(selectedItemValue)
        setSelectedItemsQuantity(ItemQuantityAdjust)
        setItemRefundTotal(selectedItemValue * props.item.basePrice);
    }

    const handleCancel = () =>{
        setItemRefundTotal(0)
        props.setModalShow(false)
    }

    const handleSubmit = () =>{
        if(!itemRefundTotal){
            toast.warn("No change detected!");
            return;
        }
        props.setModalShow(false)
        props.setRefundQuantity(selectedItemsQuantity, itemId, refundQuantity)
    }

    const renderRefundTotal = () => {
        // if (itemRefundTotal === "") {
        //     return "";
        // }

        return (
            <>
                <tr key={"orderTotal"}>
                    <td className=' text-center rounded border-color-brand p-4 pl-8 bold'><b>Refund Total</b>
                    </td>
                    <td className=' text-center rounded border-color-brand p-4 pl-8'></td>
                    <td className=' text-center rounded border-color-brand p-4 pl-8'></td>
                    <td className=' text-center rounded border-color-brand p-4  pl-8 bold'>
                        <b>{parseFloat(itemRefundTotal).toFixed(2)}</b></td>
                </tr>
            </>
        )
    }

    const renderProductContent = () => {
        if (!props.item) {
            return "";
        }

        let result = [];
        for (let i = (props.item.quantity-props.item.refundQuantity); i >= 0; i--) {
            result.push(i);
        }
        result.reverse()
        const list = result.map((quantity, key) =>  <option key={quantity} value={quantity}>{quantity}</option>)

        return (
            <tr key={props.title}>
                <td className=' text-center rounded border-color-brand p-4 pl-8'>{props.item.title}</td>
                <td className=' text-center rounded border-color-brand p-4 pl-8'>

                    <select name="cars" id="cars" onChange={(val) =>handleSelectedItemsQuantity(val.target.value)}>
                        {list}
                    </select>

                </td>
                <td className=' text-center rounded border-color-brand p-4 pl-8'>{parseFloat(props.item.basePrice).toFixed(2)}</td>
                <td className=' text-center rounded border-color-brand p-4 pl-8'>{parseFloat(props.item.basePrice * (props.item.quantity - props.item.refundQuantity)).toFixed(2)}</td>
            </tr>
        )
    }

    if(!props.item.basePrice){
        return <></>
    }

    return (
        <>
            {props.showModal ? (
                <>
                    <div
                        className="justify-center items-center flex flex-wrap overflow-x-hidden overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                <div className="text-center center mt-5">
                                    <h3 className="text-3xl font-semibold mt-5">
                                        Item Details
                                    </h3>
                                    <p className='mx-8'> Please select the item quantity that need to be refunded.</p>
                                </div>
                                <div className="relative">
                                    <table
                                        className="bg-white shadow rounded table-auto border-collapse text-sm w-full overflow-x-hidden overflow-x-scroll">
                                        <thead>
                                        <tr className='border-b rounded border-color-brand font-medium p-4 pl-8 pb-3 mt-3 text-left'
                                            key={"head"}>
                                            <th className='border-b text-center border-color-brand font-medium p-4 pl-8 table-head'>
                                                <b>Product</b></th>
                                            <th className='border-b text-center border-color-brand font-medium p-4 pl-8 table-head'>
                                                <b>Quantity</b></th>
                                            <th className='border-b text-center border-color-brand font-medium p-4 pl-8 table-head'>
                                                <b>Unit Price ({props.currency})</b></th>
                                            <th className='border-b text-center border-color-brand font-medium p-4 pl-8 table-head'>
                                                <b>Total ({props.currency})</b></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {renderProductContent()}
                                        {renderRefundTotal()}
                                        </tbody>
                                    </table>
                                </div>

                                <div
                                    className="flex items-center p-6 border-t border-solid rounded-b justify-between">
                                    <button
                                        className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded"
                                        type="button"
                                        onClick={() => handleCancel()}>
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded"
                                        type="button"
                                        onClick={() => handleSubmit()}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    <ToastContainer position={toast.POSITION.BOTTOM_CENTER} closeOnClick={false} hideProgressBar />
                </>
            ) : null}
        </>
    );
}
export default RefundItemsSelectorModel