import React from 'react';


function Header() {
  // Import result is the URL of your image

  return (
    <div className='bg-header'>
      <div className="header-container bg-white flex justify-center flex-wrap w-full">
        <div className="logo w-full mt-3 mb-3 flex justify-between sm:w-2/3">
          {/* <img className="logo-item" src="/AFL_Logo.png" alt="Logo" /> */}
          {/* <img className="logo-item runner" src="/The-Runner_A_RGB_REV.png" alt="The Runner Logo" /> */}
          <img className="logo-item marvel" src="/MS_Logo.png" alt="Marvel Stadium Logo" />
          {/* <img className="logo-item" src="/Delaware_Logo.png" alt="Logo-delaware" /> */}
        </div>
      </div>
      <div className='text-primary-brand-color text-3xl mt-5 mb-2 flex justify-center flex-wrap'>
        <h1 >Refund Portal</h1>
      </div>
    </div>
  );
}

export default Header;