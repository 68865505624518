import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { FaInfoCircle } from "react-icons/fa";
import { AnimatedTooltip } from "./animatedTooltip.tsx";

const TooltipLabel = (props) => {
    return (
        <label className="block text-gray-700 text-sm font-bold" htmlFor={props.htmlFor} >
            <span className='float-left mb-2 label-span'>{props.labelText}</span>
            <AnimatedTooltip label={props.tooltipText}>
                <span className='float-left ml-2 mt-1 tooltip-span'>
                    <i className='cursor-pointer'>
                        <FaInfoCircle />
                    </i>
                </span>
            </AnimatedTooltip>
        </label>
    )
}

export default TooltipLabel;