import { Formik, Form, ErrorMessage, Field } from "formik"
import * as Yup from 'yup'
import TooltipLabel from "../info/tooltipLabel";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';



const EmailSearchForm = (props) => {

    return (
        <div>
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={Yup.object({
                    email: Yup.string().email('Invalid email address').required('Required')
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                        props.handlePayloadChange({
                            email: values.email
                        })
                }}
            >
                {formik => (
                    <div className="bg-body flex justify-center flex-wrap -mx-3 mb-2 mt-2">
                        <Form className="mx-wid-900 bg-white shadow rounded px-8 pt-6 pb-8 mb-4 w-full sm:w-2/3 mt-4 ml-6 mr-6">
                            <div className="mb-4 working-border">
                               
                                <TooltipLabel htmlFor="email" labelText="Email Address" tooltipText="Search orders via email address supplied in store." />
                                
                                <Field data-tip data-for="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    name="email"
                                    type="email"
                                />
                              
                                <ErrorMessage component="div" className="text-red-600" name="email" />

                            </div>
                            <div className="flex items-center justify-center">
                                <button className="w-1/4 bg-component hover:bg-component text-white font-bold py-2 px-4 rounded" type="submit">Search</button>
                            </div>
                        </Form>
                        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} closeOnClick={false} hideProgressBar />
                    </div>

                )}
            </Formik>
        </div>
    )
}

export default EmailSearchForm;