import { Formik, Form, ErrorMessage, Field } from "formik"
import * as Yup from 'yup'
import DatePickerField from "./datePicker";
import TooltipLabel from "../info/tooltipLabel";

const SearchForm = (props) => {

    let cardYear = ''
    let cardMonth = ''

    const handleSetCardExpiryDate = (date) => {
        const dat =  date.split("/")
        if (dat[0]!=='') {
            const month =  dat[0].substring(0,1)
            if(month==='0'){
                cardMonth = dat[0].substring(1)
            }else {
                cardMonth = dat[0]
            }
        }
        if (dat[1]!=='') cardYear = dat[1]
    }

    return (
        <Formik
            initialValues={{
                cardTail: '',
                cardExpiryDate:'',
            }}
            validationSchema={Yup.object({
                cardExpiryDate: Yup.string().required('Required')
                    .min(7, 'Must be exactly 2 digits for a Month and 4 digits for Year')
                    .max(7, 'Must be exactly 2 digits for a Month and 4 digits for Year'),
                cardTail: Yup.string()
                    .min(4, 'Must be exactly 4 digits')
                    .max(4, 'Must be exactly 4 digits')
                    .required('Required')
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                    handleSetCardExpiryDate(values.cardExpiryDate)
                    props.handlePayloadChange({
                        creditCardLastFourDigits: values.cardTail,
                        creditCardExpiryYear: cardYear,
                        creditCardExpiryMonth: cardMonth,
                    })
            }}
        >
            {formik => (
                <div className="bg-body flex justify-center flex-wrap -mx-3 mb-2">
                    <Form className="mx-wid-900 bg-white shadow rounded px-8 pt-6 pb-8 mb-4 mt-4 w-full sm:w-2/3 ml-6 mr-6">
                        {/*<div className="mb-4">*/}
                        {/*<TooltipLabel htmlFor="cardExpiryYear" labelText="Credit Card Expiry Date" tooltipText="Please enter the expiry date of the debit/credit card that was used for the transaction." />*/}
                        {/*    <DatePicker name="cardExpiryYear"*/}
                        {/*                     showYearPicker*/}
                        {/*                     selected={cardExpiryYear}*/}
                        {/*                     dateFormat="yyyy"*/}
                        {/*                     yearItemNumber={9} autoComplete='off'*/}
                        {/*                     onChange={(date) => setCardExpiryYear(date)}*/}
                        {/*                     className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />*/}
                        {/*    <ErrorMessage component="div" className="text-red-600" name="cardExpiryYear" />*/}
                        {/*</div>*/}
                        {/*<div className="mb-4">*/}
                        {/*<TooltipLabel htmlFor="cardExpiryMonth" labelText="Credit Card Expiry Month" tooltipText="Please enter the expiry month of the debit/credit card that was used for the transaction." />*/}
                        {/*    <DatePicker name="cardExpiryMonth"*/}
                        {/*                showMonthYearPicker*/}
                        {/*                selected={cardExpiryYear}*/}
                        {/*                dateFormat="MM"*/}
                        {/*                monthItemNumber={9} autoComplete='off'*/}
                        {/*                onChange={(date) => setCardExpiryMonth(date)}*/}
                        {/*                     className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />*/}
                        {/*    <ErrorMessage component="div" className="text-red-600" name="cardExpiryMonth" />*/}
                        {/*</div>*/}

                        {/*<div className="mb-4">*/}
                        {/*    <TooltipLabel htmlFor="cardExpiryDate" labelText="Credit Card Expiry Date" tooltipText="Please enter the expiry date of the debit/credit card that was used for the transaction." />*/}
                        {/*    <DatePicker name="cardExpiryDate"*/}
                        {/*                selected={cardExpiryDate}*/}
                        {/*                onChange={(date) => handleSetCardExpiryDate(date)}*/}
                        {/*                dateFormat="MM/yy"*/}
                        {/*                showMonthYearPicker*/}
                        {/*                showFullMonthYearPicker*/}
                        {/*                showTwoColumnMonthYearPicker*/}
                        {/*                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />*/}
                        {/*    <ErrorMessage component="div" className="text-red-600" name="cardExpiryDate" />*/}
                        {/*</div>*/}

                        <div className="mb-4">
                            <TooltipLabel htmlFor="cardExpiryDate" labelText="Credit Card Expiry Date" tooltipText="Please enter the expiry date of the debit/credit card that was used for the transaction." />
                            <DatePickerField name="cardExpiryDate"
                                        dateFormat="MM/yy"
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />
                            <ErrorMessage component="div" className="text-red-600" name="cardExpiryDate" />
                        </div>

                        {/*<div className="mb-4">*/}
                        {/*    <TooltipLabel htmlFor="cardExpiryYear" labelText="Credit Card Expiry Year" tooltipText="Please enter the expiry date of the debit/credit card that was used for the transaction." />*/}
                        {/*    <YearPickerField name="cardExpiryYear"*/}
                        {/*                     dateFormat="yyyy" autoComplete='off'*/}
                        {/*                     className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />*/}
                        {/*    <ErrorMessage component="div" className="text-red-600" name="cardExpiryYear" />*/}
                        {/*</div>*/}

                        {/*<div className="mb-4">*/}
                        {/*    <TooltipLabel htmlFor="cardExpiryMonth" labelText="Credit Card Expiry Month" tooltipText="Please enter the expiry month of the debit/credit card that was used for the transaction." />*/}
                        {/*    <MonthPickerField name="cardExpiryMonth"*/}
                        {/*                dateFormat='MM' autoComplete='off'*/}
                        {/*                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />*/}
                        {/*    <ErrorMessage component="div" className="text-red-600" name="cardExpiryMonth" />*/}
                        {/*</div>*/}


                        <div className="mb-4">
                            <TooltipLabel htmlFor="cardTail" labelText="Last 4 Digits of Credit Card" tooltipText="Please enter the last 4 digits of the debit/credit card that was used for the transaction." />
                            <Field className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                   id="cardTail"
                                   name="cardTail"
                                   type="cardTail"
                            />
                            <ErrorMessage component="div" className="text-red-600" name="cardTail" />
                        </div>
                        <div className="flex items-center justify-center">
                            <button className="w-1/4 bg-component hover:bg-component text-white font-bold py-2 px-4 rounded" type="submit">Search</button>
                        </div>
                    </Form>            
                </div>

            )}
        </Formik>
    )
}

export default SearchForm;