import 'antd/dist/antd.min.css';
import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { SearchOrders } from './components/orders';
import { Navbar, Header, Footer } from './components/core/layout'
import ReceiptPreview from "./components/orders/receiptPreview";
import { useEffect, useState } from "react";

function App() {
    const isReCaptchaActive = process.env.REACT_APP_RECAPTCHA_IS_ACTIVE
    const cognitoBaseURL = process.env.REACT_APP_COGNITO_URL_BASE
    const cognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID

    const cognitoURL = `https://${cognitoBaseURL}.auth.ap-southeast-1.amazoncognito.com/oauth2/authorize?client_id=${cognitoClientId}&response_type=token&scope=openid+profile&redirect_uri=`

    if(isReCaptchaActive === 'false'){
        console.log("recaptcha disabled")
    }

    const location = useLocation();
    const params = new URLSearchParams(location.hash);
    const data = [...params.entries()]

    let authKey = '';
    if (!data || data.length < 2) {
      const uriEndcodedRedirectUrl = encodeURIComponent(window.location)
      window.location.replace(`${cognitoURL}${uriEndcodedRedirectUrl}`);
    } else {      
      for(let i =0; i < data.length; i++) {
        if (data[i].includes('id_token') || data[i].includes('#id_token') ) {
          authKey = data[i][1]
        }
      }  
    }

    useEffect(() => {
      localStorage.setItem('auth_key', authKey)
    });

    if (authKey === '') {
      return (<div>
        <h3>Redirecting...</h3>
      </div>)
    }

  return (
    <div className="bg-body flex flex-col h-screen base-container">
      <Header />
      <div className='bg-body flex flex-col h-screen'>
        {/* <Navbar /> */}
        <Routes >
          <Route path="/" element={<SearchOrders />} />
          <Route path="search" element={<SearchOrders />} />
          {/*<Route path="receipt-preview/:orderTripId/:ordersScopeToken" element={<ReceiptPreview />} />*/}
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;