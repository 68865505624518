import {React, useRef, useState} from "react";
import { Formik, Form, ErrorMessage, Field } from "formik"
import * as Yup from 'yup'
import TooltipLabel from "../info/tooltipLabel";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';

const SendEmailWithEmailAddressReceiptModal = (props) => {

  const [shopperEmailAddress, setShopperEmailAddress] = useState(props.shopperEmail)
  const ref = useRef(null);
  const receiptPortalApiBaseURL = process.env.REACT_APP_API_BASE_URL

  const sendEmailReceipt = (submittedEmail) => {
    const payload = (submittedEmail === "") ? {} : JSON.stringify({ emailAddress: submittedEmail })
    const url = receiptPortalApiBaseURL + "/orders/" + props.order.shoppingTripId + "/email"


    toast.promise(axios
      .post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + props.orderScopingToken
        }
      }),
      {
        pending: 'Sending email receipt...',
        success: 'Email receipt sent',
        error: {
          render({ data }) {
            return data.message
          }
        }
      })
      .then((response) => processSendEmailSuccess(response))
      .catch((error) => processSendEmailError(error));
  }

  const processSendEmailSuccess = (response) => {
    props.setShowModal(false);
  }

  const processSendEmailError = (error) => {

  }

  const submitForm = () => {
    sendEmailReceipt(props.shopperEmail)
  }

  const newEmailAddress = () => {
    props.setShowModal(false);
    props.setshowSendEmailReceiptModal(true)
  }



  if (!props.order.shoppingTripId) {
    return <></>
  }

  return (
    <>
      {props.showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="w-full text-3xl font-semibold">
                    Send Email Receipt
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 modal-body">
                  <div className="flex justify-left ml-4 flex-wrap -mx-3">
                    <p>A receipt will be sent to your email address that you provided.</p>
                    <p>If you want to sent the receipt to an alternative email address click on New Email address Button.</p>
                  </div>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded mr-2"
                    type="button"
                    onClick={() => submitForm()}
                  >
                    Send Email
                  </button>

                  {shopperEmailAddress !== "" &&
                      <button
                          className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded mr-2"
                          type="button"
                          onClick={() => newEmailAddress()}
                      >New Email address</button>
                  }

                  <button
                    className="bg-component hover:bg-component text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={() => props.setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          <ToastContainer position={toast.POSITION.BOTTOM_CENTER} closeOnClick={false} hideProgressBar />
        </>
      ) : null}
    </>
  );
};

export default SendEmailWithEmailAddressReceiptModal