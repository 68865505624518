import React from 'react';
import {FaInfoCircle, FaReceipt} from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { AnimatedTooltip } from '../info/animatedTooltip.tsx'
import {useNavigate} from "react-router-dom";
import {RiRefund2Fill} from "react-icons/ri";

const OrderList = (props) => {
    const navigate = new useNavigate()



    const renderTableContent = () => {
        if (!props.orders) {
            return "";
        }
        const openReceiptPreview = (shoppingTripId) => {
            props.setShowReceipt(true)
            props.showOrderDetails(shoppingTripId)
        }
        const openRefundableReceipt = (shoppingTripId) => {
            props.showOrderDetails(shoppingTripId);
            props.transactionRefresher(new Date())
            props.setShowReceipt(true);
        }

        return props.orders.map((order, index) => {
            return (
                <tr key={order.shoppingTripId}>
                    <td className='border-b rounded border-color-brand p-4 pl-8'>{order.receiptNumber}</td>
                    <td className='border-b rounded border-color-brand p-4 pl-8'>{order.shoppingTripDate}</td>
                    <td className='border-b rounded border-color-brand p-4 pl-8'>
                    {/*    <AnimatedTooltip label="Click here to see your transaction receipt.">*/}
                    {/*    <span className='float-left ml-2 mt-1'>*/}
                    {/*        <i className='cursor-pointer' onClick={() => openReceiptPreview(order.shoppingTripId)}>*/}
                    {/*            <FaReceipt />*/}
                    {/*        </i>*/}
                    {/*    </span>*/}
                    {/*    </AnimatedTooltip>*/}
                    {/*    <AnimatedTooltip label="Click here to see the transaction details.">*/}
                    {/*    <span className='float-left ml-2 mt-1'>*/}
                    {/*        <i className='cursor-pointer' onClick={() => props.showOrderDetails(order.shoppingTripId)}>*/}
                    {/*            <FaInfoCircle />*/}
                    {/*        </i>*/}
                    {/*    </span>*/}
                    {/*</AnimatedTooltip>*/}
                    {/*    <AnimatedTooltip label="Click here to email a receipt for this transaction to yourself.">*/}
                    {/*    <span className='float-left ml-2 mt-1'>*/}
                    {/*        <i className='cursor-pointer' onClick={() => props.showSendEmailReceipt(order.shoppingTripId)}>*/}
                    {/*            <FaEnvelope />*/}
                    {/*        </i>*/}
                    {/*    </span>*/}
                    {/*</AnimatedTooltip>*/}

                       <AnimatedTooltip position="TopRight"  label="Click here to select items you want to refund.">
                        <span className='float-left ml-2 mt-1'>
                            <i className='cursor-pointer' onClick={() => openRefundableReceipt(order.shoppingTripId)}>
                                <RiRefund2Fill />
                            </i>
                        </span>
                    </AnimatedTooltip>

                    </td>
                </tr>
            )
        })
    }

    return (
        <table className="mx-wid-900 bg-white shadow rounded table-auto border-collapse text-sm w-full sm:w-2/3">
            <thead>
                <tr className='border-b rounded border-color-brand font-medium p-4 pl-8 pb-3 mt-3 text-left' key={"head"}>
                    <th className='border-b border-color-brand font-medium p-4 pl-8 pr-8 mt-4 text-left table-head'><b>Receipt ID</b></th>
                    <th className='border-b border-color-brand font-medium p-4 pl-8 pr-8 mt-4 text-left table-head'><b>Shopping Trip Date</b></th>
                    <th className='border-b border-color-brand font-medium p-4 pl-8 pr-8 mt-4 text-left table-head'><b>Actions</b></th>
                </tr>
            </thead>
            <tbody>
                {renderTableContent()}
            </tbody>
        </table>
    )
}

export default OrderList;