import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black sticky top-[100vh] bg-body">
      <div className="flex justify-center px-4 d-none d-sm-block ml-2 text-primary-brand-color">
        {/* <b>Delaware North Receipt Portal</b> */}
        <img className="footer-logo" src="/Runner_Logo.png" alt="Marvel Stadium Logo" />
      </div>
    </footer>
  )
}

export default Footer;
