import React from 'react';
// import TooltipLabel from "../info/tooltipLabel";

const SearchTypeForm = (props) => {


  return (
    <div className="flex justify-center flex-wrap -mx-3">
      <form className="mx-wid-900 bg-white shadow rounded px-8 pt-6 pb-3 mb-1 w-full sm:w-2/3 mt-4 rm-mt ml-6 mr-6">
        {/* <h2 className='bold'>Search Options</h2> */}

        <div className="mt-2 w-full float-left align-middle">
          <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-primary checked:border-bg-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" name="flexRadioDefault" id="flexRadioDefault1"
            type="radio"
            value="Raw"
            checked={props.selectedSearch === "Raw"}
            onChange={props.searchRadioChange}
          />
          <h4 className='form-title bold text-gray-700 mb-1'>Search by Card Details</h4>
          <p className='ml-6'>Search for receipts using last 4 digits of your card number and the card's expiry date</p>

          {/* <TooltipLabel htmlFor="flexRadioDefault1" labelText="Search by Card Details" tooltipText="Search for receipts using last 4 digits of your card number and expiry date." /> */}
        </div>

        <div className="mt-2 w-full float-left align-middle">
          <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-primary checked:border-bg-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" name="flexRadioDefault" id="flexRadioDefault3"
            type="radio"
            value="TransactionID"
            checked={props.selectedSearch === "TransactionID"}
            onChange={props.searchRadioChange}
          />
          <h4 className='form-title bold text-gray-700 mb-1'>Search by Card Transaction ID</h4>
          <p className='ml-6'>You can find your transaction ID in your bank statement or banking app, it should look like this: <span className='bold'>MARVEL*xdpo976</span>. Use the code after the * to search, for example here <span className='bold'>"xdpo976"</span></p>
          {/* <TooltipLabel htmlFor="flexRadioDefault3" labelText="Search by Debit/Credit Card Statement Transaction ID" tooltipText="Search by bank/credit card transaction id. You can find this in your bank statement or banking app, it should look like this: MARVEL*xdpo976. Use the code after the * to search. Like this xdpo976" /> */}
        </div>

        <div className="mt-2 w-full float-left align-middle">
          <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-primary checked:border-bg-primary focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" name="flexRadioDefault" id="flexRadioDefault2"
            type="radio"
            value="Email"
            checked={props.selectedSearch === "Email"}
            onChange={props.searchRadioChange}
          />
          <h4 className='form-title bold text-gray-700 mb-1'>Search by Email Address</h4>
          <p className='ml-6'>Search for your receipts by entering the email address associated with this card</p>

          {/* <TooltipLabel htmlFor="flexRadioDefault2" labelText="Search by Email Address" tooltipText="Search for your receipts if you have previously entered your email address for the this card" /> */}
        </div>

      </form>

    </div>
  )
}

export default SearchTypeForm;